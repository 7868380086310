import auth from "./auth";

async function updateOptions(options: RequestInit) {
  const update = { ...options };

  const token = auth.getToken();

  if (token) {
    update.headers = {
      ...update.headers,
      Authorization: token,
    };
  }
  return update;
}

export default function protectedFetch(url: string, options: RequestInit) {
  return updateOptions(options).then((options) => fetch(url, options));
}
