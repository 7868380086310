import { getCookie, removeCookie, setCookie } from "typescript-cookie";

const appName = "HypefastLandingPage";
const tokenStorage = `${appName}UserToken`;

const isBrowser = typeof window !== "undefined";

const utils = {
  getToken(): string | null {
    return (isBrowser && getCookie(tokenStorage)) || null;
  },
  setToken(token: string): void {
    isBrowser && setCookie(tokenStorage, token);
  },
  removeToken(): void {
    isBrowser && removeCookie(tokenStorage);
  },
};

export default utils;
