import { API_ROOT } from "../api";
import protectedFetch from "../protectedFetch";

export interface CreateBrandInput {
  brand: CreateBrand;
}

export interface CreateBrand {
  name: string;
  phone: string;
  email_pic: string;
  description: string;
  fallback_url: string;
}

export interface UpdateBrandInput {
  brand: UpdateBrand;
}

export interface UpdateBrand {
  name: string;
  phone: string;
  fallback_url: string;
  tokopedia_url: string;
  shopee_url: string;
  facebook_url: string;
  youtube_url: string;
  instagram_url: string;
}

const api = {
  listBrands() {
    return protectedFetch(`${API_ROOT}/brands?limit=${1000}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  listBrandUsers({ brandId }) {
    return protectedFetch(`${API_ROOT}/brands/${brandId}/users`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  createBrand(brand: CreateBrandInput) {
    return protectedFetch(`${API_ROOT}/brands`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(brand),
    }).then((response) => response.json());
  },
  readBrand(brandId: number) {
    return protectedFetch(`${API_ROOT}/brands/${brandId}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  updateBrand(updateBrandInput: UpdateBrandInput, brandId: number) {
    return protectedFetch(`${API_ROOT}/brands/${brandId}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updateBrandInput),
    }).then((response) => response.json());
  },
  deleteBrand(brandId: number) {
    return protectedFetch(`${API_ROOT}/brands/${brandId}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
};

export default api;
