import React, { FC, memo } from 'react';
// import { Button as BsButton, ButtonProps as BsButtonProps, Spinner } from 'react-bootstrap';
import { Box, Button as ThemeUIButton, ButtonProps as ThemeUIButtonProps, Flex, Spinner } from 'theme-ui';
import { IconType } from 'react-icons/lib';

enum Variant {
  PRIMARY,
  SECONDARY
};

interface ButtonProps extends ThemeUIButtonProps {
  full?: boolean;
  isLoading?: boolean;
  rightIcon?: React.ReactElement<IconType>;
  leftIcon?: React.ReactElement<IconType>;
  variant: 'primary' | 'secondary' | 'outline';
  textAlignment: 'flex-start' | 'center' | 'flex-end';
}


const Button: FC<ButtonProps> = ({
  full = false, isLoading, children, leftIcon, rightIcon, className, disabled, textAlignment = 'center', variant = 'primary', sx, ...props
}) => (
  <ThemeUIButton
    sx={{
      width: full ? '100%': 'auto',
      ...sx,
    }}
    disabled={isLoading || disabled}
    variant={variant}
    {...props}
  >
    {isLoading && (
      <Spinner />
    ) || (
      <Flex
        sx={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {leftIcon}
        </Box>
        <Flex
          sx={{
            // flex: 4,
            position: rightIcon ? 'relative' : 'unset',
            right: rightIcon && 2,
            justifyContent: textAlignment,
          }}
          // p={1}
        >
          {children}
        </Flex>
        <Box
          sx={{
            flex: 1,
            position: 'relative',
            top: '2px',
          }}
        >
          {rightIcon}
        </Box>
      </Flex>
    )}
    
  </ThemeUIButton>
);

export default memo(Button);
