import { API_ROOT } from "../api";
import protectedFetch from "../protectedFetch";

const api = {
  getMetrics(params) {
    return protectedFetch(`${API_ROOT}/analytics?${params}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
};

export default api;
