import analytics from "./api/analytics";
import brand from "./api/brand";
import user from "./api/user";
import protectedFetch from "./protectedFetch";

export const API_ROOT = process.env.GATSBY_API_ROOT;

const api = {
  landingPage(pageUrl: string) {
    return fetch(`${API_ROOT}/${pageUrl}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  login({ email, password }) {
    return fetch(`${API_ROOT}/auth/signin`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        auth: {
          email,
          password,
        },
      }),
    }).then((response) => response.json());
  },
  profile() {
    return protectedFetch(`${API_ROOT}/profile`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  pages() {
    return protectedFetch(`${API_ROOT}/pages`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  page(pageUrl: string) {
    return protectedFetch(`${API_ROOT}/pages/${pageUrl}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  createPage({ content }: { content: string }) {
    return protectedFetch(`${API_ROOT}/pages`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        page: {
          content,
        },
      }),
    }).then((response) => response.json());
  },
  updatePage({ content, pageUrl }: { content: string; pageUrl: string }) {
    return protectedFetch(`${API_ROOT}/pages/${pageUrl}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        page: {
          content,
        },
      }),
    }).then((response) => response.json());
  },
  deletePage(pageUrl: string) {
    return protectedFetch(`${API_ROOT}/pages/${pageUrl}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  uploadImage({ fileName, file }: { fileName: string; file: any }) {
    return protectedFetch(`${API_ROOT}/upload_image`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        image: {
          file_name: fileName,
          file: file,
        },
      }),
    }).then((response) => response.json());
  },
  crawlMetadata({
    targetUrl,
    siteName,
  }: {
    targetUrl: string;
    siteName: string;
  }) {
    return protectedFetch(`${API_ROOT}/pages/crawl_meta_data`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        target_url: targetUrl,
        site_name: siteName,
      }),
    }).then((response) => response.json());
  },
  ...analytics,
  ...brand,
  ...user,
};

export default api;
