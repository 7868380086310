import { API_ROOT } from "../api";
import protectedFetch from "../protectedFetch";

export interface CreateUserInput {
  user: CreateUser;
}

export interface CreateUser {
  name: string;
  brand_id: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export interface UpdateUserInput {
  user: UpdateUser;
}

export interface UpdateUser {
  name: string;
  brand_id: string;
  email: string;
  role_ids: Array<number>;
}

export interface InviteUserInput {
  email: string;
  brand_id: string;
  role_ids: Array<number>;
}

const api = {
  listUsers() {
    return protectedFetch(`${API_ROOT}/users?limit=${1000}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  createUser(user: CreateUserInput) {
    return protectedFetch(`${API_ROOT}/users`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((response) => response.json());
  },
  readUser(userId: number) {
    return protectedFetch(`${API_ROOT}/users/${userId}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  updateUser(updateUserInput: UpdateUserInput, userId: number) {
    return protectedFetch(`${API_ROOT}/users/${userId}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updateUserInput),
    }).then((response) => response.json());
  },
  deleteUser(userId: number) {
    return protectedFetch(`${API_ROOT}/users/${userId}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => response.json());
  },
  inviteUser(userData: InviteUserInput) {
    return protectedFetch(`${API_ROOT}/users/invite`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userData),
    }).then((response) => response.json());
  },
  acceptInvitation({ invitationToken, name, password, passwordConfirmation }) {
    return fetch(`${API_ROOT}/users/accept_invitation`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        invitation_token: invitationToken,
        name,
        password,
        password_confirmation: passwordConfirmation,
      }),
    }).then((response) => response.json());
  },
  forgotPassword({ email }) {
    return fetch(`${API_ROOT}/users/forgot_password`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    }).then((response) => response.json());
  },
  resetPassword({ email, token, password }) {
    return fetch(`${API_ROOT}/users/set_new_password`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email,
        token,
        password,
      }),
    }).then((response) => response.json());
  },
};

export default api;
